import { Spin } from 'antd'
import { EyeOutlined, EditOutlined, DeleteOutlined } from '@ant-design/icons'
import type { FilterValue, SorterResult, TablePaginationConfig } from 'antd/lib/table/interface'
import { useEffect, useState } from 'react'
import BaseTable from '../../components/BaseTable'
import BaseModal from '../../components/BaseModal'
import FormItem from './components/Form'
import DetailItem from './components/Details'
import DeleteForm from './components/DeleteForm'
import { TenantServices } from '../../services/TenantServices'
import { utils } from '../../utils/utils'
import { storage } from '../../constants/storage'
import { filterScreen } from '../../constants/filterScreen'
import { configuration } from '../../configuration'
import { TableParamsType } from '../../types/tableParamsType'
import { TenantType } from '../../types/tenantType'
import { useRouter } from '../../hooks/useRouter'
import { useTranslation } from 'react-i18next'

function Tenants () {
  const router: any = useRouter()
  const { t } = useTranslation()
  const queryParams = new URLSearchParams(window.location.search)
  const pageCurrent = Number(queryParams.get('page') || 1)
  const textSearch = queryParams.get('keywords') || ''
  const [currentPage, setCurrentPage] = useState(pageCurrent)
  const [isNewFormModalVisible, setIsNewFormModalVisible] = useState<boolean>()
  const [isDetailModalVisible, setIsDetailModalVisible] = useState<boolean>()
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState<boolean>()
  const actionsClassName = 'text-xl px-2 hover:text-blue-400'

  const [listTenant, setListTenant] = useState([])
  const [totalItems, setTotalItems] = useState(0)
  const [loading, setLoading] = useState(true)
  const [tenant, setTenant] = useState({})

  const [tableParams, setTableParams] = useState<TableParamsType>({
    sortField: 'id',
    pagination: {
      current: 1
    }
  })

  const handleTableChange = (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue>,
    sorter: SorterResult<TenantType>
  ) => {
    setTableParams({
      sortField: 'id',
      pagination: {
        current: 1
      },
      filters,
      ...sorter
    })
  }
  const handleFilter = () => {
    router.push({
      pathname: router.location.pathname,
      search: `?${new URLSearchParams({ ...router.query, page: '1' }).toString()}`
    })
  }

  /* eslint-disable @typescript-eslint/dot-notation */
  const fetchListTenant = async (page = 1, keywords: string) => {
    try {
      const fieldParams = tableParams['order'] !== undefined ? tableParams['columnKey'] : tableParams['sortField']
      const orderParams = tableParams['order'] === 'ascend' ? 'asc' : 'desc'
      const sortParams = `${fieldParams} ${orderParams}`

      const response = (await TenantServices.getListTenants(page, keywords, sortParams)).data || []

      setListTenant(response.tenants)
      setTotalItems(response.meta.total_result)
    } catch (error) {
      utils.handleError(error)
    } finally {
      setLoading(false)
    }
  }

  const getTenant = async (id: number | string) => {
    try {
      const { data } = await TenantServices.getDetail(id)
      setTenant(data.tenant)
    } catch (error) {
      utils.handleError(error)
    }
  }

  const handleActions = async (record: any, modalType: string) => {
    await getTenant(record.id)
    if (modalType === 'View') {
      setIsDetailModalVisible(true)
    } else if (modalType === 'Delete') {
      setIsDeleteModalVisible(true)
    } else {
      setIsNewFormModalVisible(true)
    }
  }

  const handleCancel = (modalType: string) => {
    setTenant({})
    if (modalType === 'View') {
      setIsDetailModalVisible(false)
    } else if (modalType === 'Delete') {
      setIsDeleteModalVisible(false)
    } else {
      setIsNewFormModalVisible(false)
    }
  }

  const currentTenantId = parseInt(utils.getStorage(storage.CURRENT_TENANT_ID)) || ''

  const tableColumns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      width: 130,
      sorter: true
    },
    {
      title: t('message.detailsTenants.table.name'),
      dataIndex: 'name',
      key: 'name',
      width: 300,
      sorter: true
    },
    {
      title: t('message.detailsTenants.table.description'),
      dataIndex: 'description',
      key: 'description',
      width: 300,
      sorter: true,
      render: (text) => <span dangerouslySetInnerHTML={{ __html: text }} />
    },
    {
      title: t('message.detailsTenants.table.actions'),
      align: 'center',
      width: 200,
      render: (record: any) => {
        return (
          <>
            <EyeOutlined
              className={actionsClassName}
              onClick={async () => await handleActions(record, 'View')}
            />
            <EditOutlined
              className={actionsClassName}
              onClick={async () => await handleActions(record, 'Edit')}
            />
            {record.id !== currentTenantId && (
              <DeleteOutlined
                className={`${actionsClassName}`}
                onClick={async () => await handleActions(record, 'Delete')}
              />
            )}
          </>
        )
      }
    }
  ]

  useEffect(() => {
    fetchListTenant(Number(router.query.page || 1), textSearch)
    setCurrentPage(Number(router.query.page || 1))
  }, [
    JSON.stringify(tableParams),
    JSON.stringify(router.query)
  ])

  useEffect(() => {
    document.title = `Devices management - ${t('pageTitle.tenants')}`
  }, [])

  if (loading) {
    return (
      <div className="flex justify-center mt-4">
        <Spin></Spin>
      </div>
    )
  }

  return (
    <div>
      <BaseTable
        columns={tableColumns}
        dataSource={listTenant}
        total={totalItems}
        pageSize={configuration.pageSize}
        callbackFilter={handleFilter}
        filterScreen={filterScreen.tenants}
        setCurrentPage={setCurrentPage}
        currentPage={currentPage}
        handleTableChange={handleTableChange}
        isScrollTable={false}
        handleAddNewDevice={() => setIsNewFormModalVisible(true)}
      />
      {isNewFormModalVisible && (
        <BaseModal
          visible={isNewFormModalVisible}
          handleCancel={() => handleCancel('Edit')}
          content={
            <FormItem
              tenant={tenant}
              forceRefeshTable={() => {
                fetchListTenant(Number(router.query.page || 1), textSearch)
                setCurrentPage(Number(router.query.page || 1))
                handleCancel('Edit')
              }}
            />
          }
          title={Object.keys(tenant).length ? t('message.tenantForm.titleUpdate') : t('message.tenantForm.titleCreate')}
          width={1000}
          footer={null}
        />
      )}
      {isDetailModalVisible && (
        <BaseModal
        visible={isDetailModalVisible}
        handleCancel={() => handleCancel('View')}
        content={
          <DetailItem
            tenant={tenant}
          />
        }
        title={t('message.tenantForm.titleDetail')}
        width={1000}
        footer={null}
      />
      )}
      {isDeleteModalVisible && (
        <BaseModal
          visible={isDeleteModalVisible}
          handleCancel={() => handleCancel('Delete')}
          content={
            <DeleteForm
              tenant={tenant}
              forceRequestList={() => {
                fetchListTenant(Number(router.query.page || 1), textSearch)
                setCurrentPage(Number(router.query.page || 1))
                handleCancel('Delete')
              }}
              handleCancelModal={() => handleCancel('Delete')}
            />
          }
          title={t('message.tenantForm.titleDelete')}
          width={350}
          footer={null}
          bodyStyle={{ padding: '5px 24px 14px' }}
        />
      )}
    </div>
  )
}

export default Tenants
