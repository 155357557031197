import { Button, Col, Form, Input, Row, Select } from 'antd'
import { useEffect, useState } from 'react'
import { utils } from '../../../../utils/utils'
import { CKEditor } from '@ckeditor/ckeditor5-react'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import { GroupsServices } from '../../../../services/GroupsServices'
import { UserServices } from '../../../../services/UserServices'
import { updateUserInfo } from '../../../../redux/slices/authSlice'
import { useAppDispatch } from '../../../../hooks'
import { useTranslation } from 'react-i18next'

const FormItem = (props: any) => {
  const { t } = useTranslation()
  const { forceRefeshTable, group } = props
  const [form] = Form.useForm()
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [description, setDescription] = useState('')
  const isCreate = Boolean(!Object.keys(group).length)
  const [users, setUsers] = useState([])
  const [selectedUsers, setSelectedUsers] = useState([])
  const [selectedManager, setSelectedManager] = useState([])
  const dispatch = useAppDispatch()

  const getUserInfo = async () => {
    try {
      const { data } = await UserServices.getDetail()
      dispatch(updateUserInfo(data.user))
    } catch (e) {
      utils.handleError(e)
    }
  }

  const handleFinish = async (value: any) => {
    try {
      setIsSubmitting(true)
      if (isCreate) {
        const formData = { ...value, description }
        await GroupsServices.createNewGroup(formData)
        utils.handleSuccessNotify(t('groups.createSuccess'))
      } else {
        const formData = { ...value, description }
        await GroupsServices.updateGroup(group.id, formData)
        utils.handleSuccessNotify(t('groups.updateSuccess'))
      }
      forceRefeshTable && forceRefeshTable()
    } catch (e) {
      utils.handleError(e)
    } finally {
      setIsSubmitting(false)
      getUserInfo()
    }
  }

  const handleSelectUserChange = (values) => {
    setSelectedUsers(values)
    setSelectedManager((prevSelectedUsers) =>
      prevSelectedUsers ? prevSelectedUsers.filter((user) => !values.includes(user)) : []
    )
  }

  const handleSelectManagerChange = (values) => {
    setSelectedManager(values)
    setSelectedUsers((prevSelectedUsers) =>
      prevSelectedUsers ? prevSelectedUsers.filter((user) => !values.includes(user)) : []
    )
  }

  const fetchListUser = async () => {
    try {
      const response = (await GroupsServices.getListUsersTenant()).data || []
      const userData = response.data.users.map((user: any) => {
        return {
          label: user.full_name,
          value: user.id
        }
      })
      setUsers(userData)
    } catch (error) {
      utils.handleError(error)
    }
  }

  useEffect(() => {
    fetchListUser()
    const dataForm = { ...group }
    setSelectedUsers(dataForm?.user_ids || [])
    setSelectedManager(dataForm?.manager_ids || [])
    if (!isCreate) {
      form.setFieldsValue({ ...dataForm })
    }
  }, [JSON.stringify(group)])

  return (
    <Form
      scrollToFirstError
      form={form}
      layout="vertical"
      onFinish={handleFinish}
    >
      <Row gutter={20}>
        <Col span={24}>
          <Form.Item
            label={t('message.tenantForm.name')}
            name="name"
            required
            rules={[{ required: true, message: t('message.requiredRule') }]}
          >
            <Input maxLength={250} autoComplete="off" />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label={t('groups.selectManagerGroup')}
            name="manager_ids"
            required
            rules={[{ required: true, message: t('message.requiredRule') }]}
          >
            <Select
              mode="multiple"
              style={{ width: '100%', marginBottom: '20px' }}
              placeholder={t('message.tenantForm.placeholder')}
              options={users.map((user) => ({
                ...user,
                disabled: selectedUsers?.includes(user.value)
              }))}
              onChange={handleSelectManagerChange}
            />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item label={t('groups.selectUserGroup')} name="user_ids">
            <Select
              mode="multiple"
              style={{ width: '100%', marginBottom: '20px' }}
              placeholder={t('message.tenantForm.placeholder')}
              options={users.map((user) => ({
                ...user,
                disabled: selectedManager?.includes(user.value)
              }))}
              onChange={handleSelectUserChange}
            />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item label={t('message.tenantForm.description')} name="description">
            <CKEditor
              editor={ClassicEditor}
              data={isCreate ? '' : group.description}
              onChange={(_: any, editor: any) => {
                setDescription(editor.getData())
              }} />
          </Form.Item>
        </Col>
      </Row>
      <Button
        type="primary"
        htmlType="submit"
        loading={isSubmitting}
        className="block ml-auto"
      >
        {isCreate ? t('create') : t('update')}
      </Button>
    </Form>
  )
}

export default FormItem
