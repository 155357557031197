import { PlusOutlined } from '@ant-design/icons'
import { Button, Input, Select, Table } from 'antd'
import { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { configuration } from '../../configuration'
import useDebounce from '../../hooks/useDebounce'
import { utils } from '../../utils/utils'
import { filterScreen as filterDisplay } from '../../constants/filterScreen'
import { roleSelect } from '../../constants/role'
import { useAppDispatch, useAppSelector } from '../../hooks'
import { handleSelectRole } from '../../redux/slices/userFilterSlice'
// import { handleChangeAllowedDomain } from '../../redux/slices/tenantFilterSlice'
import DeviceFilter from '../DeviceFilter'
import RequestFilter from '../RequestFilter'
import { handleSetKeyWord, handleUrlDevice } from '../../redux/slices/deviceFilterSlice'
import { handleUrlRequest } from '../../redux/slices/requestFilterSlice'
import { handleUrlDeviceHistory } from '../../redux/slices/deviceHistorySlice'
import { routesAdminObj } from '../../routes/routes'
import { useRouter } from '../../hooks/useRouter'
import { useTranslation } from 'react-i18next'

interface IBaseTable {
  columns: any
  dataSource: any
  allowedDomains?: any[]
  pageSize?: number
  total?: number
  onchangePage?: any
  handleAddNewDevice?: any
  callbackAfterChangePage?: any
  callbackHandleSearch?: any
  callbackHandleAutoSearch?: any
  filterScreen?: string
  setCurrentPage?: any
  currentPage?: any
  handleTableChange?: any
  isScrollTable?: any
  callbackFilter?: any
}

const BaseTable = (props: IBaseTable) => {
  const {
    columns,
    dataSource,
    // allowedDomains = [],
    pageSize = configuration.pageSize,
    total = 0,
    handleAddNewDevice,
    callbackAfterChangePage,
    callbackHandleAutoSearch,
    filterScreen,
    setCurrentPage,
    currentPage,
    handleTableChange,
    isScrollTable = true,
    callbackFilter
  } = props
  const { t } = useTranslation()
  const queryParams = new URLSearchParams(window.location.search)
  const textSearchInput = queryParams.get('keywords') || ''
  const [keywords, setKeywords] = useState(textSearchInput)
  const [loading, setLoading] = useState(false)
  const [firstLoad, setFirstLoad] = useState(true)
  const userFilter = useAppSelector((state) => state.userFilter)
  const deviceFilter = useAppSelector((state) => state.deviceFilter)
  // const tenantFilter = useAppSelector((state) => state.tenantFilter)
  const auth = useAppSelector((state) => state.auth)
  const history = useHistory()
  const debouncedValue = useDebounce(keywords, 1500)
  const dispatch = useAppDispatch()
  const router = useRouter()
  const onchangePage = async (page: number) => {
    try {
      setCurrentPage(page)
      const newQuery: any = {
        ...router.query,
        page
      }
      history.push({
        pathname: history.location.pathname,
        search: `?${new URLSearchParams(newQuery).toString()}`
      })

      // do something when change page
      callbackAfterChangePage && (await callbackAfterChangePage(page))
      if (window.location.href.includes(`${routesAdminObj.devices.path}`) && !window.location.href.includes(`${routesAdminObj.devices.path}/`)) {
        dispatch(handleUrlDevice(window.location.href))
      }
      if (window.location.href.includes(`${routesAdminObj.requests.path}`) && !window.location.href.includes(`${routesAdminObj.requests.path}/`)) {
        dispatch(handleUrlRequest(window.location.href))
      }
      if (window.location.href.includes(`${routesAdminObj.devices.path}/`) && !window.location.href.includes(`${routesAdminObj.devices.path}-histories/`)) {
        dispatch(handleUrlDeviceHistory(window.location.href))
      }
    } catch (e) {
      utils.handleError(e)
    }
  }
  const isStatusStatistics = history.location.pathname === '/status_statistics'
  const textSearch = (type: string) => {
    switch (type) {
      case filterDisplay.users:
        return t('pageTitle.search.byUserName')
      case filterDisplay.requests:
        return t('pageTitle.search.byTitleToName')
      case filterDisplay.devices:
        if (auth.userInfo.role === 'user') {
          return t('pageTitle.search.byDeviceNameToDeviceCodeFirst')
        } else {
          return t('pageTitle.search.byDeviceNameToDeviceCodeSecond')
        }
      case filterDisplay.historyDevices:
        return t('pageTitle.search.byUpdater')
      case filterDisplay.tenants:
        return t('pageTitle.search.byTenant')
      case filterDisplay.groups:
        return t('pageTitle.search.byGroups')
      default:
        break
    }
  }
  const defaultValueInput = (type: string) => {
    switch (type) {
      case filterDisplay.users:
        return `${textSearchInput}`
      case filterDisplay.requests:
        return `${textSearchInput}`
      case filterDisplay.devices:
        return `${deviceFilter.key_word}`
      case filterDisplay.historyDevices:
        return `${textSearchInput}`
      default:
        break
    }
  }

  useEffect(() => {
    try {
      // do something when  search
      if (!firstLoad) {
        setLoading(true)
        callbackHandleAutoSearch && callbackHandleAutoSearch(debouncedValue)
        setLoading(false)
        const newQuery: any = {
          ...router.query,
          page: 1,
          keywords: debouncedValue.toString()
        }
        history.push({
          pathname: history.location.pathname,
          search: `?${new URLSearchParams(newQuery).toString()}`
        })
        setCurrentPage(1)
      }
      if (window.location.href.includes(`${routesAdminObj.devices.path}`) && !window.location.href.includes(`${routesAdminObj.devices.path}/`)) {
        dispatch(handleUrlDevice(window.location.href))
      }
      if (window.location.href.includes(`${routesAdminObj.requests.path}`) && !window.location.href.includes(`${routesAdminObj.requests.path}/`)) {
        dispatch(handleUrlRequest(window.location.href))
      }
      if (window.location.href.includes(`${routesAdminObj.devices.path}/`) && !window.location.href.includes(`${routesAdminObj.devices.path}-histories/`)) {
        dispatch(handleUrlDeviceHistory(window.location.href))
      }
    } catch (e) {
      utils.handleError(e)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedValue])

  useEffect(() => {
    if (window.location.href.includes(`${routesAdminObj.devices.path}?`) || window.location.href.includes(`${routesAdminObj.devices.path}/?`) ||
      window.location.href.includes(`${routesAdminObj.myDevices.path}?`) || window.location.href.includes(`${routesAdminObj.myDevices.path}/?`)) {
      dispatch(handleSetKeyWord(textSearchInput))
    }
  })

  useEffect(() => {
    setKeywords(textSearchInput)
    setFirstLoad(false)
  }, [textSearchInput])

  const flexClass = filterScreen === filterDisplay.users ? 'justify-start' : 'justify-between'

  return (
    <>
      <>
        <div className={`flex ${flexClass} gap-x-2`}>
          {!isStatusStatistics && (
            <Input
              defaultValue={keywords !== '' ? keywords : defaultValueInput(filterScreen)}
              value={keywords}
              className="max-w-3xl mb-4 h-8"
              placeholder={textSearch(filterScreen)}
              onChange={(event: any) => setKeywords(event.target.value)}
            />
          )
          }
          {handleAddNewDevice && (
            <Button
              type="primary"
              className="mb-4"
              onClick={handleAddNewDevice}
              icon={<PlusOutlined />}
            >
              {t('pageTitle.personal.createNew.titleButton')}
            </Button>
          )}
          {filterScreen === filterDisplay.users && (
            <Select
              value={userFilter.role ? userFilter.role : null}
              style={{ width: 200 }}
              className="select-role"
              placeholder={t('pageTitle.search.byRole')}
              onSelect={(value) => {
                callbackFilter && callbackFilter()
                dispatch(handleSelectRole(value))
              }}
              options={roleSelect}
            />
          )}
        </div>
        {filterScreen === filterDisplay.requests && (
          <RequestFilter callbackFilter={callbackFilter} />
        )}
        {filterScreen === filterDisplay.devices && (
          <DeviceFilter callbackFilter={callbackFilter} />
        )}
        {/* {filterScreen === filterDisplay.tenants && (
          <Select
            value={tenantFilter.allowedDomains ? tenantFilter.allowedDomains : null}
            style={{ width: 300 }}
            className="select-allowed"
            placeholder={t('pageTitle.search.byAllowedDomains')}
            onSelect={(value) => {
              callbackFilter && callbackFilter()
              dispatch(handleChangeAllowedDomain(value))
            }}
            options={allowedDomains}
          />
        )} */}
      </>

      <Table
        columns={columns}
        rowKey="id"
        scroll={
          isScrollTable && { x: 1300 }
        }
        dataSource={dataSource}
        pagination={{
          position: ['bottomRight'],
          showSizeChanger: false,
          current: currentPage,
          // defaultCurrent: currentPage,
          pageSize,
          total,
          hideOnSinglePage: true,
          onChange: onchangePage,
          showTotal: (totalItem, range) => `${range[0]}-${range[1]} of ${totalItem} items`
        }}
        className="mt-5"
        loading={loading}
        onChange={handleTableChange}
        locale={{
          emptyText: t('noData'),
          triggerDesc: t('triggerDesc'),
          triggerAsc: t('triggerAsc'),
          cancelSort: t('cancelSort')
        }}
      />
    </>
  )
}
export default BaseTable
