import { Spin } from 'antd'
import { useEffect, useState } from 'react'
import { utils } from '../../utils/utils'
import { DeviceServices } from '../../services/DeviceServices'
import BaseTable from '../../components/BaseTable'
import { useTranslation } from 'react-i18next'

const Statistics = () => {
  const [loading, setLoading] = useState(true)
  const { t } = useTranslation()
  const [listDeviceStatistics, setListDeviceStatistics] = useState([])

  const getData = async () => {
    try {
      const response = (await DeviceServices.getListDeviceStatistics()) || []
      setListDeviceStatistics(response || [])
      console.log('type', response)
    } catch (error) {
      utils.handleError(error)
    } finally {
      setLoading(false)
    }
  }
  const columnsDeviceStatistics = [
    {
      title: t('statistics.device_type'),
      dataIndex: 'type',
      key: 'type',
      width: 100,
      sorter: false,
      render: (type) => (type === '' ? 'undefined' : type)
    },
    {
      title: t('statistics.available'),
      dataIndex: 'available',
      key: 'available',
      width: 100,
      sorter: (a, b) => a.available - b.available
    },
    {
      title: t('statistics.using'),
      dataIndex: 'using',
      key: 'using',
      width: 100,
      sorter: (a, b) => a.using - b.using
    },
    {
      title: t('statistics.broken'),
      dataIndex: 'broken',
      key: 'broken',
      width: 100,
      sorter: (a, b) => a.broken - b.broken
    },
    {
      title: t('statistics.warranty'),
      dataIndex: 'warranty',
      key: 'warranty',
      width: 100,
      sorter: (a, b) => a.warranty - b.warranty
    },
    {
      title: t('statistics.deleted'),
      dataIndex: 'deleted',
      key: 'deleted',
      width: 100,
      sorter: (a, b) => a.deleted - b.deleted
    },
    {
      title: t('statistics.preparing'),
      dataIndex: 'preparing',
      key: 'preparing',
      width: 100,
      sorter: (a, b) => a.preparing - b.preparing
    },
    {
      title: t('statistics.total'),
      dataIndex: 'total',
      key: 'total',
      width: 100,
      sorter: (a, b) => a.total - b.total
    }
  ]
  useEffect(() => {
    getData()
  }, [])

  if (loading) {
    return (
      <div className="flex justify-center mt-4">
        <Spin></Spin>
      </div>
    )
  }
  return (
    <div>
      <BaseTable
        columns={columnsDeviceStatistics}
        dataSource={listDeviceStatistics}
      />
    </div>
  )
}
export default Statistics
