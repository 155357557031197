import { Button, Form } from 'antd'
import { RequestServices } from '../../../../services/RequestServices'
import { utils } from '../../../../utils/utils'
import { useTranslation } from 'react-i18next'
interface ModalProps {
  dataRecord: any
  forceRequestList?: any
  handleCancelModal?: any
}

const DeleteForm = ({ dataRecord, forceRequestList, handleCancelModal }: ModalProps) => {
  const [form] = Form.useForm()
  const { t } = useTranslation()
  const handleFinish = async () => {
    try {
      await RequestServices.deleteRequest(dataRecord.id)
      utils.handleSuccessNotify(t('message.deleteFormRequest.success'))
      forceRequestList && forceRequestList()
    } catch (error) {
      utils.handleError(error)
    } finally {
      handleCancelModal && handleCancelModal()
    }
  }

  return (
    <>
      <Form form={form} layout="vertical" onFinish={handleFinish}>
        <>
          <Form.Item name="Request">
            <span className="text-sm">{t('message.deleteFormRequest.confirmDelete')}</span>
          </Form.Item>
          <div className="d-flex justify-end">
            <Button type="default" onClick={handleCancelModal} className="mr-2">
              {t('cancel')}
            </Button>
            <Button type="primary" htmlType="submit">
            {t('delete')}
            </Button>
          </div>
        </>
      </Form>
    </>
  )
}

export default DeleteForm
