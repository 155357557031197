export const apiConstants = {
  LOGIN_VIA_GG: '/api/v1/authentication/auth_by_google',
  LOGOUT: '/api/v1/authentication/logout',
  RE_GET_ACCESS_TOKEN: '/api/v1/refresh_token',
  USERS: '/api/v1/users',
  USERS_COMPANY: '/api/v1/tenants/list_user_company',
  USERS_SEARCH: '/api/v1/users/search',
  USERS_PROFILE: '/api/v1/users/show_info',
  DEVICE: '/api/v1/devices',
  MY_DEVICE: '/api/v1/devices/my_devices',
  REQUESTS: '/api/v1/requests',
  HISTORY_DEVICES: '/api/v1/devices/:id/history_devices',
  DEVICE_NAME: '/api/v1/devices/search_by_name',
  EXPORT_DEVICES: '/api/v1/export_csv.csv',
  TENANTS: '/api/v1/tenants',
  loginVV: 'api/v1/authentication/login_complete',
  loginOauth2: 'oauth2/authorize',
  loginOauth2Me: 'oauth2/me',
  SERVICE: 'services',
  CHANGE_LANGUAGE: 'change_language',
  LANG: 'lang',
  IS_LOGIN: 'is_login',
  USER_LOGOUT: 'user_logout',
  GROUPS: '/api/v1/groups',
  STATUS_STATISTICS: '/api/v1/devices/status_statistics'
}
